import React, {Component, useEffect, useState} from "react";
import axios from "../../api";
import * as Auth from "../../AuthService";
import {Button, Col, Descriptions, message, Row, Statistic, Table, Tag} from "antd";

import {CheckOutlined, DownloadOutlined, DownOutlined, UpOutlined} from '@ant-design/icons';

import '../helpers.css'
import {createScopeLink, downloadExcel, getColumnSearchProps, openLinkInNewTab} from "../../helpers";
import {DataCard} from "../helpers/cards/Card";
import {HelpModal} from "../helpers/modals/HelpModal";
import {projectCurrency, properties, ROP_MIN_LABEL} from "../../properties";
import {GetBack} from "../helpers/cards/MaterialBlock";
import {StandardTable} from "../helpers/TablePanel";
import {CustomTabs} from "../helpers/CustomTabs";
import DescriptionsItem from "antd/es/descriptions/Item";
import api from "../../api";

class IterationResultsTable extends Component {

    state = {
        searchText: ""
    };

    constructor(props) {
        super(props);
        this.state = {
            filteredInfo: {}, sortedInfo: {}, newPagination: {
                current: 1, pageSize: 10
            }, total: 0, _filters: {}
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.records !== this.props.records && this.props.records) {
            this.setState({
                materialFilters: this.props.records.map(v => {
                    return {text: v['material'], value: v['material']}
                })
            })
        }
    }

    getDataSource = () => {
        const records = this.props.records;
        return records.map((record, i) => {
            return {
                ...record, // "material": <MaterialBlock material={record['material']} />,
                "new_min": <div
                    style={{color: (record['new_min'] - record['rop'] > 0) ? 'red' : 'green'}}>{record['new_min']}</div>,
                "new_max": <div
                    style={{color: (record['new_max'] - record['max'] > 0) ? 'red' : 'green'}}>{record['new_max']}</div>,
                "min_value_change_K": <div
                    style={{color: (record['min_value_change_K'] < 0) ? 'red' : 'green'}}>{Math.round(record['min_value_change_K'] / 1000 * 100) / 100}</div>,
                "min_value_diff": record['min_value_change_K'],
                "max_value_diff": record['max_value_change_K'],
                "max_value_change_K": <div
                    style={{color: (record['max_value_change_K'] < 0) ? 'red' : 'green'}}>{Math.round(record['max_value_change_K'] / 1000 * 100) / 100}</div>,
                'key': i,
                'price': <Statistic style={{marginTop: -15}} className='stat-price' value={record['price']}/>

            }
        })
    }

    handleSearch = (selectedKeys, dataIndex, confirm) => {
        confirm()
        this.setState({searchText: selectedKeys[0]});
        this.props.handlePageChange({
            current: 1,
            pageSize: this.props.pagination.pageSize
        }, {}, this.props.sorter, {...this.props._filters, [dataIndex]: selectedKeys[0]})
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ""});
        this.props.handlePageChange({
            current: 1,
            pageSize: this.props.pagination.pageSize
        }, {}, this.props.sorter, this.props._filters)
    };

    getColumns = () => {
        return [{
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            width: 100, ...getColumnSearchProps("material", true, this.state, this.handleReset, this.handleSearch),
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 200, ...getColumnSearchProps("description", false, this.state, this.handleReset, this.handleSearch)
        }, {
            title: 'Lead Time (days)', dataIndex: 'lt_days', key: 'lt_days',
        }, {
            title: 'Number of BOMs', dataIndex: 'EQ BOM Count', key: 'EQ BOM Count',
        }, {
            title: 'Annual Demand', dataIndex: 'demand', key: 'demand',
        }, {
            title: ROP_MIN_LABEL, dataIndex: 'rop', key: 'rop',
        }, {
            title: 'Old MAX', dataIndex: 'max', key: 'max',
        }, {
            title: `New ${ROP_MIN_LABEL}`, dataIndex: 'new_min', key: 'new_min',
        }, {
            title: 'New MAX', dataIndex: 'new_max', key: 'new_max',
        }, {
            title: 'Item Price', dataIndex: 'price', key: 'price',
        }, {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            filters: [{value: true, text: 'Approved'}, {value: false, text: 'Not Approved'}],
            render: approved => approved ? <CheckOutlined style={{color: "#107E70"}}/> : null
        },];
    }

    check_Obj = (old, _new) => {
        return JSON.stringify(old) !== JSON.stringify(_new)
    }

    handleTableChange = (newPagination, _filters, sorter) => {
        if (this.check_Obj(newPagination, this.props.pagination) || this.check_Obj(sorter, this.props.sorter) || this.check_Obj(_filters, this.props._filters)) {
            const pagination = {
                current: newPagination.current, pageSize: newPagination.pageSize
            }

            if (newPagination.pageSize !== this.props.pagination.pageSize) {
                pagination.current = 1
            }
            if (!sorter['order']) {
                sorter = {}
            }

            this.props.handlePageChange(pagination, this.props.search, sorter, _filters)
        }

    }

    render() {
        return (<div>
            <StandardTable data={this.getDataSource()}
                           scroll={700}
                           loading={this.props.loading}
                           total={this.props.total}
                           onChange={this.handleTableChange}
                           pageSize={this.props.pagination.pageSize}
                           currentPage={this.props.pagination.current}
                           tableProps={{
                               rowClassName: (record) => record.approved ? 'approved' : ''
                           }}
                           columns={this.getColumns()}/>
        </div>);
    }

}

export class IterationOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [], projectId: props.location.pathname.split('/')[2], iterationInfo: {
                results: {
                    should_buy_beautified: '', can_sell_beautified: ''
                }, constants: {}, scope: {}
            }, _filters: {}, helpModal: false, loading: false, pagination: {
                current: 1, pageSize: 10
            }, search: {}, sorter: {}
        }
    }

    getIterationResults(pagination = this.state.pagination, _search = this.state.search, sorter = this.state.sorter, _filters = this.state._filters) {
        if (!this.state.loading) {
            const project_id = this.props.location.pathname.split('/')[2]
            const iteration_id = this.props.location.pathname.split('/')[5]

            this.setState({loading: true})
            axios.post(`/projects/${project_id}/iterations/${iteration_id}/min-max?page=${pagination.current}&size=${pagination.pageSize}`, {
                _search, _filters, sorter
            }, Auth.createConfig())
                .then(json => {
                    this.setState({
                        records: json.data.items, total: json.data.total, pagination, _filters, sorter, search: _search
                    })

                }).catch(error => {
                console.log(error)
            }).finally(() => this.setState({loading: false}))
        }
    }

    componentDidMount() {
        const project_id = this.props.location.pathname.split('/')[2]
        const iteration_id = this.props.location.pathname.split('/')[5]
        axios.get(`/projects/${project_id}/iteration/${iteration_id}/overview`, Auth.createConfig())
            .then(json_results => {
                this.setState({iterationInfo: json_results.data})
            }).catch(error => {
            console.log(error)
        })
        this.getIterationResults()
    }

    exportIteration = () => {
        const project_id = this.props.location.pathname.split('/')[2]
        const iteration_id = this.props.location.pathname.split('/')[5]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${project_id}/iterations/${iteration_id}/min-max/export`, config)
            .then(json => {
                downloadExcel(json.data, `Proposed MIN-MAX ${this.state.iterationInfo.iteration_name}.xlsx`)
            }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return (<div style={{minHeight: '90vh', height: 'max-content'}}>
            <Row>
                <Col span={3} style={{padding: 15}}>
                    <GetBack link={`/projects/${this.state.projectId}/min-max`}/>
                </Col>
                <Col span={18}>
                    <DataCard title={<div className='text-center'
                                          style={{fontSize: 24}}>
                        {this.state.iterationInfo.iteration_name} {this.state.iterationInfo.scope.label && `- [ ${this.state.iterationInfo.scope.label} ]`}
                        {this.state.iterationInfo.iteration_name &&
                            <DownloadOutlined style={{marginLeft: 20, color: "#107E70"}}
                                              onClick={this.exportIteration}/>}
                        <Button className='float-right' style={{marginLeft: 10}}
                                onClick={() => this.setState({helpModal: true})}>?</Button>
                    </div>}
                              style={{margin: 10}}>
                        <Tag color={properties.kbrColors.pantone301Blue}>Scenario Summary</Tag>
                        <Row>
                            <Col span={8}>
                                <Statistic className='text-center' title='Total Number of Materials'
                                           value={this.state.iterationInfo.results.total_number}/>
                            </Col>
                            <Col span={8}>
                                <Col span={8}>
                                    <Statistic className='text-center' title='Materials Analyzed'
                                               value={this.state.iterationInfo.results.items_affected}/>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col span={24}>
                                    <Statistic className='text-center' title='Not Affected Materials'
                                               value={this.state.iterationInfo.results.total_number_not_selected}/>
                                </Col>
                            </Col>
                        </Row>
                        <Tag color={properties.kbrColors.pantone301Blue}>Assumptions</Tag>
                        <Row>
                            <Col span={5}>
                                <Statistic className='text-center' title='Customer Service Level'
                                           value={this.state.iterationInfo.constants.service_level}
                                           suffix={this.state.iterationInfo.constants.csf && `(CSF: ${this.state.iterationInfo.constants.csf})`}/>
                            </Col>
                            <Col span={5}>
                                <Statistic className='text-center' title='Cost to place one order'
                                           value={this.state.iterationInfo.constants.s}/>
                            </Col>
                            <Col span={5}>
                                <Statistic className='text-center' title='Default price per line'
                                           value={this.state.iterationInfo.constants.default_price}/>
                            </Col>
                            <Col span={5}>
                                <Statistic className='text-center' title='Mean Absolute Deviation'
                                           value={this.state.iterationInfo.constants.mad}/>
                            </Col>
                            <Col span={4}>
                                <Statistic className='text-center' title='Holding Cost'
                                           value={this.state.iterationInfo.constants.holding && this.state.iterationInfo.constants.holding * 100}
                                           suffix={'%'}/>
                            </Col>
                        </Row>
                    </DataCard>
                </Col>
                <Col span={3}/>
            </Row>
            <div style={{margin: 10}}>

                <IterationResultsTable loading={this.state.loading}
                                       records={this.state.records}
                                       total={this.state.total}
                                       currentPage={this.state.currentPage}
                                       size={this.state.size}
                                       pagination={this.state.pagination}
                                       sorter={this.state.sorter}
                                       _filters={this.state._filters}
                                       search={this.state.search}
                                       handlePageChange={(pagination, search, sorter, filters) => this.getIterationResults(pagination, search, sorter, filters)}/>
            </div>
            <HelpModal isModalVisible={this.state.helpModal}
                       setModalVisible={(value) => this.setState({helpModal: value})}/>

        </div>);
    }

}


export class IOScenarioOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scenarioInfo: {
                calculations_type: ''
            }
        }
    }


    componentDidMount() {
        const project_id = this.props.location.pathname.split('/')[2]
        const iteration_id = this.props.location.pathname.split('/')[5]
        axios.get(`/projects/${project_id}/iteration/${iteration_id}/overview`, Auth.createConfig())
            .then(json_results => {
                this.setState({scenarioInfo: json_results.data})
            }).catch(error => {
            console.log(error)
        })
    }

    render() {
        const templates = {
            'standard': <IterationOverview {...this.props} />,
            'recommendation': <Recommendation scenarioInfo={this.state.scenarioInfo} {...this.props} />
        }

        return <div>
            {templates[this.state.scenarioInfo['calculations_type']]}
        </div>

    }

}

function RecommendationStatsOverview({scenarioInfo}) {

    const exportIteration = () => {

        const hide = message.loading('Preparing Scenario for download...', 0, () => {
        });

        const project_id = window.document.location.pathname.split('/')[2]
        const iteration_id = window.document.location.pathname.split('/')[5]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${project_id}/iterations/${iteration_id}/min-max/export`, config)
            .then(json => {
                downloadExcel(json.data, `Proposed MIN-MAX ${scenarioInfo.iteration_name}.xlsx`)
            }).catch(error => {
            console.log(error)
        }).finally(() => setTimeout(hide, 1))
    }

    const stats = {
        scope: {
            stats: [{
                label: 'Scope Name', key: 'name', isLink: true,
                link: <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3} label={"Scope Name"}>
                    <a onClick={() => openLinkInNewTab(createScopeLink(scenarioInfo['scope']['project_id'], scenarioInfo['scope']['_id']))}
                       >{scenarioInfo['scope']['name']}</a>
                </DescriptionsItem>
            }],
            source: 'scope',


        },
        records: {
            stats: [
                {label: 'Total Number of Records', key: 'total_number'},
                {label: 'Total Number of Records (Has been selected)', key: 'total_number_selected'},
                {label: 'Total Number of Records (Not selected)', key: 'total_number_not_selected'},
            ],
            source: 'results'

        },
        service: {
            stats: [
                {label: 'Total Predicted Goods Issued', key: 'total_gi'},
                {label: 'Total Predicted Stock-outs', key: 'total_so'},
                {label: 'Predicted Service level', key: 'predicted_sl', suffix: '%'},

            ],
            source: 'results'

        },
        stockValue: {
            stats: [
                {label: 'Total Current Average Stock', key: 'current_avs', suffix: projectCurrency},
                {label: 'Total New Average Stock', key: 'current_ns', suffix: projectCurrency},
                {label: 'Total Average Stock Savings', key: 'ave_stock_savings', suffix: '%'},
            ],
            source: 'results'

        },
    }

    const createGroupStats = (_group) => {
        return stats[_group]['stats'].map(_stat => {
            return <Descriptions.Item span={1} label={_stat['label']}>
                {_stat['isLink'] ?  _stat['link'] :
                <b>
                    <Statistic valueStyle={{fontSize: 14}} suffix={_stat['suffix']}
                               value={scenarioInfo[stats[_group]['source']][_stat['key']]}/>
                </b>}

            </Descriptions.Item>
        })
    }

    const labels = {
        records: 'Records',
        service: 'Service',
        stockValue: 'Stock Value',
        topRecommendations: 'Top Recommendations'
    }

    const createFullStats = () => {
        const groups = []
        for (let _group of Object.keys(stats)) {
            groups.push(
                <Descriptions size={'small'}>
                    {createGroupStats(_group)}
                </Descriptions>
            )
        }
        return groups
    }

    return <DataCard title={<div>Scenario: <b>{scenarioInfo.iteration_name}</b> {scenarioInfo.iteration_name &&
        <DownloadOutlined className={"beautiful-link"} onClick={exportIteration}/>}</div>}>
        {createFullStats()}
    </DataCard>
}

function RecommendationsIOTable({
                                    loading = false, total = 0, pageSize = 10, data = [],
                                    page = 1, handleChange, sorters = {},
                                }) {

    const [state, setState] = useState({})
    const [expandedKeys, setExpandedKeys] = useState([])
    const [examples, setExamples] = useState([])

    const handleSearch = (selectedKeys, dataIndex, confirm) => {
        confirm()
        setState({searchText: selectedKeys[0]});
        handleChange({current: 1, pageSize: pageSize}, {[dataIndex]: selectedKeys[0]})
    };

    const handleReset = clearFilters => {
        clearFilters();
        setState({searchText: ""});
        handleChange({current: 1, pageSize: pageSize})
    };

    const getOrder = field => {
        return sorters.field === field ? sorters.order : null
    }

    const columns = [
        {
            key: 'material',
            dataIndex: 'material',
            title: 'Material',
            ...getColumnSearchProps("material", true, state, handleReset, handleSearch)
        },
        {
            key: 'description',
            dataIndex: 'description',
            title: 'Description',
            width: '15%', ...getColumnSearchProps("description", false, state, handleReset, handleSearch)
        },
        {key: '', dataIndex: 'lt_days', title: 'Lead Time', width: '4%'},
        {key: '', dataIndex: 'recommendation', title: 'Recommendation', width: '15%'},
        {
            title: 'Current', children: [
                {key: '', dataIndex: 'rop', title: ROP_MIN_LABEL, width: '4%'},
                {key: '', dataIndex: 'max', title: 'Max', width: '4%'},
            ]
        },

        {
            title: 'New', children: [
                {key: '', dataIndex: 'new_rop', title: ROP_MIN_LABEL, width: '4%'},
                {key: '', dataIndex: 'new_max', title: 'Max', width: '4%'},
            ]
        },

        {
            key: 'stockouts',
            dataIndex: 'stockouts',
            title: 'Stock-Outs',
            width: '5%',
            sorter: true,
            sortOrder: getOrder('stockouts'),
        },
        {
            key: 'gi', dataIndex: 'gi', title: 'Issues', width: '5%', sorter: true, sortOrder: getOrder('gi'),
        },
        {
            key: 'use_pm', dataIndex: 'use_pm', title: 'Use PM Demand', width: '5%', render: use_pm => use_pm ? 'Yes' : 'No',
        },
        {key: '', dataIndex: 'ave_savings', title: 'Ave Savings'},

        {
            title: `Average Stock (${projectCurrency})`, children: [
                {key: 'ave_stock_current', dataIndex: 'ave_stock_current', title: 'Current'},
                {
                    key: 'ave_stock_new',
                    dataIndex: 'ave_stock_new',
                    title: 'New',
                    sorter: true,
                    sortOrder: getOrder('ave_stock_new'),
                },

                {
                    key: 'ave_stock_savings',
                    dataIndex: 'ave_stock_savings',
                    title: 'Saving (%)',
                    width: '5%',
                    sorter: true,
                    sortOrder: getOrder('ave_stock_savings'),
                    render: ave_stock_savings => <div>{ave_stock_savings} %</div>
                },

                {
                    key: 'savings_money',
                    dataIndex: 'savings_money',
                    title: `Saving (${projectCurrency})`,
                    width: '5%',
                    sorter: true,
                    sortOrder: getOrder('savings_money'),
                    render: (ave_stock_savings, item) => <Statistic valueStyle={{fontSize: 12}} value={item.ave_stock_current - item.ave_stock_new} />
                }, {
                    title: '',
                    dataIndex: '',
                    key: 'max',
                }

            ]
        },

    ]

    const getExamples = record => {
        console.log(record)
        const project_id = window.document.location.pathname.split('/')[2]

        if (!Object.keys(record).indexOf('plant_info') > -1) {
            api.post(`/projects/${project_id}/material-analysis/${record.material}/stockAllocation`, {min: record.new_rop, max: record.new_max})
            .then(response => {
                record.plant_info = response.data
                setExamples(response.data)
            })
        }
    }

    const onTableRowExpand = (expanded, record) => {
        let keys = [];
        if (expanded) {
            keys.push(record.key);
        }
        setState({...state, expandedRowKeys: keys});
    }

    const getNestedTable = (record) => {
        return <Table className="nested-table-allocation"
                      bordered={true}
                      pagination={false}
                      dataSource={examples || []} columns={nestedColumns()}/>
    }

    const nestedColumns = () => [
        {
            title: 'Plant',
            dataIndex: 'plant',
            key: 'plant',

        }, {
            title: 'MRP Type',
            dataIndex: 'MRP Type',
            key: 'plant',

        }, {
            title: 'Plant Lead Time',
            dataIndex: 'plant_lead_time',
            key: 'plant',

        }, {
            title: 'Plant price',
            dataIndex: 'plant_price',
            key: 'plant',
        }, {
            title: 'Plant Stock',
            dataIndex: 'plant_stock_level',
            key: 'plant',

        }, {
            title: 'Plant MAX',
            dataIndex: 'max',
            key: 'plant',

        },  {
            title: `Plant ${ROP_MIN_LABEL}`,
            dataIndex: 'rop',
            key: 'plant',

        }, {
            title: 'Plant New MAX',
            dataIndex: 'appr_max',
            key: 'plant',

        },  {
            title: `Plant New ${ROP_MIN_LABEL}`,
            dataIndex: 'appr_min',
            key: 'plant',

        }, {
            title: 'Plant Installed',
            dataIndex: 'quantity',
            key: 'plant',

        },
    ]

    useEffect(() => {
        console.log(expandedKeys)
    }, [expandedKeys])


    return <DataCard>
        <StandardTable size={'small'}
                       loading={loading}
                       total={total}
                       currentPage={page}
                       columns={columns}
                       scroll={350}
                       tableProps={{
                           rowClassName: (record) => record.approved ? 'approved' : '',
                           expandIconColumnIndex: 14,
                           expandedRowKeys: expandedKeys,
                           expandable: {

                               expandedRowRender: (record) => (
                                   <p
                                       style={{
                                           margin: 0,
                                       }}
                                   >
                                       {getNestedTable(record)}
                                   </p>
                               ),
                               onExpand: (expanded, record) => {
                                   if (expanded) {
                                       setExpandedKeys([record.key])
                                       getExamples(record)

                                   } else {
                                       setExpandedKeys([])
                                   }
                               },
                               expandIcon: ({expanded, onExpand, record}) =>
                                   expanded ? (
                                       <UpOutlined onClick={(e) => onExpand(record, e ,expanded)}/>
                                   ) : (
                                       <DownOutlined onClick={(e) => onExpand(record, e, expanded)}/>
                                   ),
                               rowExpandable: (record) => record.material,
                           }
                       }}
                       onChange={handleChange}
                       pageSize={pageSize}
                       data={data}/>
    </DataCard>

}

function Recommendation({scenarioInfo}) {

    const defaultSort = {
        field: 'savings_money',
        order: 'descend'
    }

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({})
    const [sorters, setSorters] = useState(defaultSort)


    const getIterationResults = (_page = page, size = pageSize, _filters = filters) => {
        if (!loading) {
            const project_id = window.document.location.pathname.split('/')[2]
            const iteration_id = window.document.location.pathname.split('/')[5]
            setLoading(true)
            axios.post(`/projects/${project_id}/iterations/${iteration_id}/min-max?page=${_page}&size=${size}`, {
                _search: '', _filters: _filters, sorters: prepareSorters()
            }, Auth.createConfig())
                .then(json => {
                    for (const item of json.data.items) {
                        item.key = item.material
                    }
                    setData(json.data.items)
                    setTotal(json.data.total)
                    setPage(_page)
                    setPageSize(size)
                    setFilters(_filters)
                }).catch(error => {
                console.log(error)
            }).finally(() => setLoading(false))
        }
    }

    const prepareSorters = () => {
        let sorter = {};

        sorter[sorters['field']] = sorters['order'] === 'ascend' ? 1 : -1

        return sorter
    }

    const handleTableChange = (newPagination, _filters = null, sorter = null) => {
        setPage(newPagination.current)
        setPageSize(newPagination.pageSize)
        if (_filters) {
            setFilters({...filters, ..._filters})
        } else {
            setFilters({})
        }

        if (sorter) {
            setSorters(sorter)
        } else {
            setSorters(defaultSort)
        }
    }

    useEffect(() => {
        getIterationResults()
    }, [page, sorters]);

    useEffect(() => {
        getIterationResults()
    }, [scenarioInfo]);

    useEffect(() => {
        setPage(1)
    }, [pageSize, filters, sorters]);

    const getTabs = () => [{
        name: 'Scenario Outcome',
        key: 'items',
        children: <RecommendationsIOTable handleChange={handleTableChange} data={data} loading={loading} total={total}
                                          page={page} pageSize={pageSize} sorters={sorters}/>
    }, {
        name: `Recommendations Summary`,
        key: 'recommendations',
        children: <RecommendationsCombinedStats scenarioInfo={scenarioInfo}/>
    }]

    return <div style={{padding: 5}}>
        <Row>
            <Col span={2}/>
            <Col span={20}>
                <RecommendationStatsOverview scenarioInfo={scenarioInfo}/>
            </Col>
        </Row>
        <Row style={{marginTop: 10, height: 'calc(100% - 600px)'}}>
            <CustomTabs tabsData={getTabs()}/>
        </Row>
    </div>
}

function RecommendationsCombinedStats({scenarioInfo}) {

    useEffect(() => {
        console.log(scenarioInfo)
    }, []);

    const columns = [{
        title: 'Recommendation',
        dataIndex: 'recommendation'
    }, {
        title: 'Count',
        dataIndex: 'count',
        render: count => <div>
            <Statistic value={count}
                       valueStyle={{fontSize: 14}}
                       suffix={`/ ${scenarioInfo.results.total_number_selected} (${countPercentage(count, scenarioInfo.results.total_number_selected)} %)`}/>
        </div>
    }]

    const countPercentage = (_number, _total) => {
        return Math.round((_number / _total) * 100)
    }

    return scenarioInfo && <DataCard>
        <StandardTable size={'small'}
                       columns={columns}
                       scroll={350}
                       data={scenarioInfo.results.recommendation_stats}/>
    </DataCard>

}
